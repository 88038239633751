import PropTypes from 'prop-types';
import React from 'react';
// import { Col, Row } from 'react-simple-flex-grid';

import Container from '../../global/Container';
import Section from '../../global/Section';
import { Button, ContactUsContainer, IconTextContainer, SectionContainer, StyledA } from './styled';
import Feature from '../../global/Feature';
// import Icon from '../../global/Icon';
// import { Heading2, Paragraph } from '../../global/Typography';

const Company = ({ data, lng }) => {
    return (
        <SectionContainer>
            {data.sections.map((section, index) => (
                <Section
                    section={section}
                    key={section.id}
                    lng={lng}
                    alignment={index % 2 === 0 ? 'left' : 'right'}
                    index={index}
                    firstSectionHeading1
                />
            ))}
            <Container>
                <Feature data={data.feature} hasBoxShadow={false} />
            </Container>
            {/* <Container narrow>
                <ContactUsContainer>
                    <Heading2 marginBottom="24px">{data.contact.title}</Heading2>
                    <Row gutter={24}>
                        <Col xs={12} sm={6}>
                            <Paragraph marginbottomonsmall="24px">
                                {data.contact.description}
                            </Paragraph>
                        </Col>
                        <Col xs={12} sm={6}>
                            <div>
                                <StyledA href={`tel:${data.contact.phoneNumber}`}>
                                    <IconTextContainer>
                                        <Icon name="phone" outlined={false} />
                                        <Paragraph>{data.contact.phoneNumber}</Paragraph>
                                    </IconTextContainer>
                                </StyledA>
                            </div>
                            <div>
                                <Button
                                    type="button"
                                    onClick={() => {
                                        window.location.href = `mailto:data.contact.emailAddress`;
                                    }}
                                >
                                    <IconTextContainer>
                                        <Icon name="mail" outlined={false} />
                                        <Paragraph>{data.contact.emailAddress}</Paragraph>
                                    </IconTextContainer>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </ContactUsContainer>
            </Container> */}
        </SectionContainer>
    );
};

Company.propTypes = {
    data: PropTypes.shape({
        contact: PropTypes.shape({
            description: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            emailAddress: PropTypes.string.isRequired,
            phoneNumber: PropTypes.string.isRequired,
        }).isRequired,
        feature: PropTypes.shape({}).isRequired,
        sections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }).isRequired,
    lng: PropTypes.string.isRequired,
};

export default Company;
