import styled from 'styled-components';
import { colors } from '../../../theme';

export const Button = styled.button`
    border: 0;
    outline: none;
    cursor: pointer;
    background: transparent;
`;

export const ContactUsContainer = styled.div`
    margin-bottom: 130px;

    @media all and (max-width: 768px) {
        margin-bottom: 28px;
    }
`;

export const IconTextContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    i {
        margin-right: 16px;
        color: ${colors.blue};
        :hover {
            color: #59adff;
        }

        :focus {
            color: rgba(0, 0, 0, 0.78);
        }
    }

    p {
        color: ${colors.blue};
        font-weight: 600;
        :hover {
            color: #59adff;
        }

        :focus {
            color: rgba(0, 0, 0, 0.78);
        }
    }
`;

export const SectionContainer = styled.div`
    margin-top: 130px;

    @media all and (max-width: 768px) {
        margin-top: 56px;
    }

    @media all and (max-width: 601px) {
        margin-top: 30px;
    }
`;

export const StyledA = styled.a`
    border: 0;
    outline: none;
    cursor: pointer;
    background: transparent;
`;
